.postMain {
  background-color: white;
  border: 1px solid gray;
  margin-top: 10px;
  padding: 0 10px 10px 10px;
}

.postTitle,
.postAuthor {
  margin-bottom: 2px;
}

.postAuthor,
.postDate {
  font-size: 16px;
  font-family: "Montserrat";
  color: gray;
  font-weight: 600;
}

.postHeader {
  display: flex;
  align-items: center;
  height: 50px;
}

.postHeaderBackIcon {
  position: absolute;
  justify-content: start !important;
  z-index: 1;
  cursor: pointer;
}

.postHeaderIcons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.postHeaderIcons > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.postTitle {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
}

.postDate {
  margin-bottom: 10px;
}

.postContent {
  white-space: pre-line;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
}

.hidden {
  display: none !important;
}

/* POST CREATE & POST EDIT */

/* currently post edit is using post creates css to avoid repeating code */

.postCreateMain {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 10px 10px 10px;
  margin-top: 10px;
  border: 1px solid gray;
}

.postCreateTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.postHeaderTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100% !important;
  font-size: 18px;
}

.postCreateTitle-input,
.postCreateContent-input {
  margin-bottom: 20px;
  font-size: 18px;
}

.postCreateTitle-input {
  height: 32px;
  padding-left: 5px;
}

.postCreateContent-input {
  flex-grow: 1;
  padding: 5px;
}

.postCreate-submitBtn {
  height: 32px;
  font-size: 18px;
}

.postCreateTitle-input,
.postCreateContent-input:focus {
  outline: none;
}

/* POST LIST */

.postListMain {
  display: flex;
  flex-direction: column;
}

.postListHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

/* POST CARD */

.postCardMain {
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  margin-bottom: 10px;
  height: 160px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  background-color: white;
  cursor: pointer;
}

.postCardTitle {
  font-family: "Roboto";
  font-size: 20px;
}

.postCardAuthor,
.postCardDate {
  font-size: 14px;
  color: gray;
}

.postCardContent {
  white-space: pre-line;
}
