.navBarMain {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100vw;
  border-bottom: 1px solid black;
  background-color: #6c8c6c;
  z-index: 5;
}

.navMobileMenuIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 35px;
  left: 39px;
}

.navMobileMenu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 100px;
  left: 0;
  width: 200px;
  border: 1px solid gray;
  border-top: none;
  background-color: white;
  z-index: 2;
}

.navMobileMenu > * {
  color: #6c8c6c;
}

.hidden {
  display: none;
}

.navMobileMenu > div {
  height: 60px;
  display: flex;
  padding-left: 40px;
  align-items: center;
  border-bottom: 1px solid gray;
}

.navMobileMenuLastElement {
  border-bottom: none !important;
}

a {
  text-decoration: none;
  color: #6c8c6c;
  font-family: "Montserrat";
}

.logoContainer {
  height: 89px;
  width: 200px;
}

.logoImg {
  height: auto;
  width: 200px;
}

.navDesktop {
  display: none;
}

.navDesktopLinks {
  display: flex;
  font-size: 18px;
}

.navTitle {
  grid-column-start: 2;
  position: relative;
  right: 30px;
  color: white;
  font-size: 20px;
  font-family: "Poppins";
}

.navTitleLoggedIn {
  grid-column-start: 2;
  position: relative;
  left: 55px !important;
  color: white;
  font-size: 20px;
  font-family: "Poppins";
}
