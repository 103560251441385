.loginMain {
  margin-top: 50px;
  padding-top: 16vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid gray;
  font-size: 18px;
}

.loginInnerContainer {
  width: 300px;
}

.flex {
  display: flex;
}

.loginInput {
  display: block;
  height: 26px;
  font-size: 16px;
}

.loginInput:focus {
  outline: none;
}

.labels {
  /* margin-right: 10px; */
  margin-bottom: 10px;
  width: 100%;
}

.labels > div {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  height: 26px;
}

.inputs > input {
  margin-bottom: 15px;
  width: 200px;
}

.loginBtn {
  height: 26px;
  width: 100%;
  font-size: 16px;
}

.loginHeader1 {
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}

.loginHeader2 {
  margin-bottom: 20px;
  font-weight: 600;
}

.loginBackIcon {
  position: relative;
  bottom: 135px;
  right: 122px;
}
