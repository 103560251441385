.main {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.mainView {
  display: flex;
  flex-direction: column;
  margin: 100px 20px 20px 20px;
}

.mainBackgroundImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50vh;
  left: 50vw;
  height: 20px;
  width: 20px;
  z-index: -2;
}

.mainBackgroundImgContainer > img {
  object-fit: cover;
}

.mainBackgroundImg2 {
  display: none;
}

.mainBackgroundImg3 {
  display: none;
}

.navTitle,
.navTitleLoggedIn {
  display: none;
}

@media (min-width: 600px) {
  .mainView {
    margin-right: 20%;
    margin-left: 20%;
  }

  .navMobileMenuIcon {
    display: none !important;
  }

  .navBarMain {
    display: flex;
    justify-content: space-between !important;
    padding-left: 30px;
    padding-right: 60px;
  }

  .navDesktopLinks > div {
    color: white;
    cursor: pointer;
    margin-left: 60px;
  }

  .navDesktopLinks > div > a {
    color: white;
  }

  .logoContainer {
    position: relative;
    top: -5px;
    display: flex;
    justify-content: center;
  }

  .navDesktop {
    display: flex !important;
  }

  .postMain,
  .postCreateMain,
  .postEditMain {
    border: 1px solid gray;
    min-height: 80vh;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
  }

  .loginMain {
    border: 1px solid gray;
  }

  .mainBackgroundImg1 {
    display: none;
  }

  .mainBackgroundImg2 {
    display: block;
  }
}

@media (min-width: 700px) {
  .navBarMain {
    padding-left: 40px;
    padding-right: 60px;
  }
}

@media (min-width: 900px) {
  .mainView {
    margin-right: 25%;
    margin-left: 25%;
  }

  .navBarMain {
    padding-right: 100px;
  }

  .navDesktopLinks > div {
    margin-left: 80px;
  }
}

@media (min-width: 1200px) {
  .mainView {
    margin-right: 28%;
    margin-left: 28%;
  }

  .mainBackgroundImg2 {
    display: none;
  }

  .mainBackgroundImg3 {
    display: block;
  }

  .navTitle, .navTitleLoggedIn {
    display: block;
  }
}
