@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&family=Sanchez&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.main {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.mainView {
  display: flex;
  flex-direction: column;
  margin: 100px 20px 20px 20px;
}

.mainBackgroundImgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50vh;
  left: 50vw;
  height: 20px;
  width: 20px;
  z-index: -2;
}

.mainBackgroundImgContainer > img {
  object-fit: cover;
}

.mainBackgroundImg2 {
  display: none;
}

.mainBackgroundImg3 {
  display: none;
}

.navTitle,
.navTitleLoggedIn {
  display: none;
}

@media (min-width: 600px) {
  .mainView {
    margin-right: 20%;
    margin-left: 20%;
  }

  .navMobileMenuIcon {
    display: none !important;
  }

  .navBarMain {
    display: flex;
    justify-content: space-between !important;
    padding-left: 30px;
    padding-right: 60px;
  }

  .navDesktopLinks > div {
    color: white;
    cursor: pointer;
    margin-left: 60px;
  }

  .navDesktopLinks > div > a {
    color: white;
  }

  .logoContainer {
    position: relative;
    top: -5px;
    display: flex;
    justify-content: center;
  }

  .navDesktop {
    display: flex !important;
  }

  .postMain,
  .postCreateMain,
  .postEditMain {
    border: 1px solid gray;
    min-height: 80vh;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
  }

  .loginMain {
    border: 1px solid gray;
  }

  .mainBackgroundImg1 {
    display: none;
  }

  .mainBackgroundImg2 {
    display: block;
  }
}

@media (min-width: 700px) {
  .navBarMain {
    padding-left: 40px;
    padding-right: 60px;
  }
}

@media (min-width: 900px) {
  .mainView {
    margin-right: 25%;
    margin-left: 25%;
  }

  .navBarMain {
    padding-right: 100px;
  }

  .navDesktopLinks > div {
    margin-left: 80px;
  }
}

@media (min-width: 1200px) {
  .mainView {
    margin-right: 28%;
    margin-left: 28%;
  }

  .mainBackgroundImg2 {
    display: none;
  }

  .mainBackgroundImg3 {
    display: block;
  }

  .navTitle, .navTitleLoggedIn {
    display: block;
  }
}

.postMain {
  background-color: white;
  border: 1px solid gray;
  margin-top: 10px;
  padding: 0 10px 10px 10px;
}

.postTitle,
.postAuthor {
  margin-bottom: 2px;
}

.postAuthor,
.postDate {
  font-size: 16px;
  font-family: "Montserrat";
  color: gray;
  font-weight: 600;
}

.postHeader {
  display: flex;
  align-items: center;
  height: 50px;
}

.postHeaderBackIcon {
  position: absolute;
  justify-content: start !important;
  z-index: 1;
  cursor: pointer;
}

.postHeaderIcons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.postHeaderIcons > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
}

.postTitle {
  font-family: "Poppins";
  font-size: 28px;
  font-weight: 600;
}

.postDate {
  margin-bottom: 10px;
}

.postContent {
  white-space: pre-line;
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 18px;
}

.hidden {
  display: none !important;
}

/* POST CREATE & POST EDIT */

/* currently post edit is using post creates css to avoid repeating code */

.postCreateMain {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 0 10px 10px 10px;
  margin-top: 10px;
  border: 1px solid gray;
}

.postCreateTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.postHeaderTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100% !important;
  font-size: 18px;
}

.postCreateTitle-input,
.postCreateContent-input {
  margin-bottom: 20px;
  font-size: 18px;
}

.postCreateTitle-input {
  height: 32px;
  padding-left: 5px;
}

.postCreateContent-input {
  flex-grow: 1;
  padding: 5px;
}

.postCreate-submitBtn {
  height: 32px;
  font-size: 18px;
}

.postCreateTitle-input,
.postCreateContent-input:focus {
  outline: none;
}

/* POST LIST */

.postListMain {
  display: flex;
  flex-direction: column;
}

.postListHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

/* POST CARD */

.postCardMain {
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  margin-bottom: 10px;
  height: 160px;
  overflow: hidden;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  background-color: white;
  cursor: pointer;
}

.postCardTitle {
  font-family: "Roboto";
  font-size: 20px;
}

.postCardAuthor,
.postCardDate {
  font-size: 14px;
  color: gray;
}

.postCardContent {
  white-space: pre-line;
}

.loginMain {
  margin-top: 50px;
  padding-top: 16vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid gray;
  font-size: 18px;
}

.loginInnerContainer {
  width: 300px;
}

.flex {
  display: flex;
}

.loginInput {
  display: block;
  height: 26px;
  font-size: 16px;
}

.loginInput:focus {
  outline: none;
}

.labels {
  /* margin-right: 10px; */
  margin-bottom: 10px;
  width: 100%;
}

.labels > div {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  height: 26px;
}

.inputs > input {
  margin-bottom: 15px;
  width: 200px;
}

.loginBtn {
  height: 26px;
  width: 100%;
  font-size: 16px;
}

.loginHeader1 {
  margin-bottom: 20px;
  font-weight: 600;
  text-align: center;
}

.loginHeader2 {
  margin-bottom: 20px;
  font-weight: 600;
}

.loginBackIcon {
  position: relative;
  bottom: 135px;
  right: 122px;
}

.navBarMain {
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100vw;
  border-bottom: 1px solid black;
  background-color: #6c8c6c;
  z-index: 5;
}

.navMobileMenuIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 35px;
  left: 39px;
}

.navMobileMenu {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 100px;
  left: 0;
  width: 200px;
  border: 1px solid gray;
  border-top: none;
  background-color: white;
  z-index: 2;
}

.navMobileMenu > * {
  color: #6c8c6c;
}

.hidden {
  display: none;
}

.navMobileMenu > div {
  height: 60px;
  display: flex;
  padding-left: 40px;
  align-items: center;
  border-bottom: 1px solid gray;
}

.navMobileMenuLastElement {
  border-bottom: none !important;
}

a {
  text-decoration: none;
  color: #6c8c6c;
  font-family: "Montserrat";
}

.logoContainer {
  height: 89px;
  width: 200px;
}

.logoImg {
  height: auto;
  width: 200px;
}

.navDesktop {
  display: none;
}

.navDesktopLinks {
  display: flex;
  font-size: 18px;
}

.navTitle {
  grid-column-start: 2;
  position: relative;
  right: 30px;
  color: white;
  font-size: 20px;
  font-family: "Poppins";
}

.navTitleLoggedIn {
  grid-column-start: 2;
  position: relative;
  left: 55px !important;
  color: white;
  font-size: 20px;
  font-family: "Poppins";
}

